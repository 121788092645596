<template>
  <div class="card card-white admin-card text-dark">
    <div class="card-header">
      <h4>Posts</h4>
    </div>
    <div class="card-body pt-0">
      <vue-table
        v-if="vTable.headers"
        :values="vTable.values"
        :headers="vTable.headers"
        :actions="vTable.actions"
        :options="vTable.options"
        ref="vtable"
      />
    </div>
  </div>
</template>

<script>
import VueTable from "@/components/VueTables/VueTable.vue";

export default {
  components: { VueTable },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "name",
          sortable: true,
        },
        {
          title: "username",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn btn-secondary",
          callback: "onShowDetails",
          tooltip: "show details",
          icon: "fas fa-pen",
        },
        {
          buttonClass: "btn btn-primary",
          callback: "onDelete",
          tooltip: "delete",
          icon: "fas fa-times",
        },
      ],
      values: {
        total: 3,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 15,
        data: [
          {
            id: 1,
            name: "Pedro Aznar",
            username: "paznar",
          },
          {
            id: 2,
            name: "Charlie Alberti",
            username: "chalberti",
          },
          {
            id: 3,
            name: "Gustavo Cerati",
            username: "gcerati",
          },
        ],
      },
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
  }),
};
</script>
